import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useRef, useContext, useState, useCallback, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TextItem, TextMarkedContent } from 'pdfjs-dist/types/src/display/api'
import { CustomTextRenderer, OnGetTextSuccess } from 'react-pdf/dist/cjs/shared/types';
import { range, head, last, find, findLast, indexOf } from 'lodash'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useResizeObserver } from 'usehooks-ts';
import Translation from '../../translation/Translation';
import LangContext from '../app/App';
import './PDFViewer.scss';
import { SearchNavParams } from '../view/Params';

// this import is required. Even if it seems to work without it, it breaks in a production build
import 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

type Size = {
  width?: number
  height?: number
}

export function PDFViewer({ file, textAttributeUri }: { file: string, textAttributeUri?: string }) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [matchedPages, setMatchedPages] = useState<number[]>([]);
  const langContext = useContext(LangContext);
  const translate = (key: string): string => Translation.getTranslation(key, langContext);
  const ref = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState<number>(0)
  const onResize = (size: Size) => {
    // this prevents flickering from a scrollbar changing the size
    if (size.width && size.width !== width && Math.abs(size.width - width) > 50) {
      setWidth(size.width)
    }
  }
  useResizeObserver({ ref, onResize });

  const onDocumentLoadSuccess = useCallback((pdf: PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
    if (textQueries) {
      Promise.all(
        range(1, pdf.numPages + 1).map((page) => {
          return pdf.getPage(page)
            .then(p => p.getTextContent())
            .then(({ items }) => { return { page: page, hasMatch: pdfTextContains(items, textQueries) } })
        })
      ).then(searchResults => searchResults.flatMap(({ page, hasMatch }) => hasMatch ? [page] : []))
        .then(matches => { setMatchedPages(matches); setPageNumber(head(matches) || 1) })
    } else {
      setPageNumber(1);
    }
  }, [setNumPages, setMatchedPages, setPageNumber])


  const onDocumentLoadError = (_: Error) => {
    setNumPages(0);
    setPageNumber(0);
  }

  const changePage = useCallback((offset: number) => setPageNumber(prevPageNumber => prevPageNumber + offset), [setPageNumber])

  const previousPage = useCallback(() => changePage(-1), [changePage])

  const nextPage = useCallback(() => changePage(1), [changePage])

  const previousMatch = useCallback(() => {
    const next = findLast(matchedPages, (i) => i < pageNumber)
    if (next) setPageNumber(next)
  }, [pageNumber, setPageNumber, matchedPages])

  const nextMatch = useCallback(() => {
    const next = find(matchedPages, (i) => i > pageNumber)
    if (next) setPageNumber(next)
  }, [pageNumber, setPageNumber, matchedPages])

  const textQueries = textAttributeUri ? getTextQueries(textAttributeUri) : undefined;

  const customTextRenderer = textQueries ? queryRenderer(textQueries) : undefined;

  const currentMatchPage = indexOf(matchedPages, pageNumber)
  const paginator = <>
    <div className="pdf-pagination">
      {numPages > 1 && <>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          {translate('pdfPrevPage')}
        </button>
        <span>{translate('page')}: {pageNumber} / {numPages}</span>
        <button type="button" disabled={pageNumber >= numPages} onClick={nextPage} >
          {translate('pdfNextPage')}
        </button>
      </>
      }
    </div>
    {textQueries &&
      <div className="pdf-pagination">
        <br />
        <button type="button" disabled={pageNumber <= (head(matchedPages) || pageNumber)} onClick={previousMatch}>
          {translate('pdfPrevPageMatch')}
        </button>
        {currentMatchPage > -1
          ? <span>{translate('matchedPage')}: {currentMatchPage + 1} / {matchedPages.length}</span>
          : <span>{translate('noMatchHere')}</span>
        }
        <button type="button" disabled={pageNumber >= (last(matchedPages) || pageNumber)} onClick={nextMatch} >
          {translate('pdfNextPageMatch')}
        </button>
      </div>}
  </>

  return (
    <>
      {paginator}
      <div ref={ref} className="container pdfviewer">
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          <Page
            width={width}
            customTextRenderer={customTextRenderer}
            pageNumber={pageNumber} />
        </Document>
      </div>
      {paginator}
    </>
  );
}

const getTextQueries = (textAttributeUri: String): RegExp | undefined => {
  const searchSessionString = sessionStorage.getItem("monodicumSearch");
  if (searchSessionString) {
    const searchSession: SearchNavParams = JSON.parse(searchSessionString);
    const textSearches = searchSession.querys?.filter(q => q.uri === textAttributeUri)
    if (textSearches?.length) {
      return new RegExp(textSearches
        .map(q => q.value)
        .join("|"), "ig")
    }
  }
}
const queryRenderer = (textQueries: RegExp): CustomTextRenderer =>
  ({ str }) => str.replace(textQueries, value => `<mark class="search-result">${value}</mark>`)

const pdfTextContains = (items: (TextItem | TextMarkedContent)[], textQueries: RegExp) =>
  items.find((value) => 'str' in value ? textQueries.test(value.str) : false)
