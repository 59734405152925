import { useEffect, useReducer, useMemo, ReactElement, useContext, useCallback } from 'react';
import { QueryParameter } from "../../hooks/useQuery";
import { assertNever, uriToClasName } from "../../util";
import './SearchBar.scss';
import { useUrlData } from "../../hooks/useUrlData";
import * as Search from "./Search";
import { Attribute, CategoryEntry, CategoryEntryGuard } from "../../model/attribute";
import Autosuggest from "react-autosuggest";
import Translation from '../../translation/Translation';
import LangContext from '../app/App';
import { EntityDescription } from '../../model/entity-description';

export function SearchBar(props: { entity: EntityDescription, search: Attribute[], modeButtons: ReactElement }) {
  const [urlState, setUrlState] = useUrlData<Search.Params>()('query');
  const [hidden, _] = useUrlData<Search.Params>()('hideFromSearch');
  const [query, dispatch] = useReducer(reduce, urlState ? urlState : []);
  const langContext = useContext(LangContext);
  const translate = (key: string): string => {
    return Translation.getTranslation(key, langContext);
  }

  useEffect(() => {
    dispatch({ kind: "Set", parameters: urlState ? urlState : [] })
  }, [urlState])

  const addParameter = (uri: string): void => {
    const attribute = props.search.find(a => a.uri === uri);
    if (attribute) {
      dispatch({ kind: "AddParameter", param: { uri: attribute.uri, value: "" } });
    }
  }

  const search = () => { setUrlState(query); }
  const isHidden = (uri: string): boolean => {
    return !!hidden && hidden.indexOf(uri) !== -1;
  }

  return <div className="searchBar">
    <div className="parameters">
      <select data-intro={translate("introSearch2")} className="simple-select" value="" onChange={e => { addParameter(e.target.value) }}>
        <option value="" disabled hidden>{translate("search_criteria")}</option>
        <option />
        {
          props.search.filter(a => a.searchOrder !== undefined && !isHidden(a.uri)).map(a =>
            <option key={a.uri} value={a.uri}>
              {a.label}
            </option>
          )
        }
      </select>
      {
        query.map((qp, i) =>
          isHidden(qp.uri) ? null :
            <QueryParameterComponent entity={props.entity} key={i} index={i} dispatch={dispatch} params={query} search={props.search} />)
      }
    </div>
    <div className="button-column">
      <button data-intro={translate("introSearch3")} onClick={search} className="searchButton">{Translation.getTranslation("search", langContext)}</button>
      {props.modeButtons}
    </div>
  </div>;
}

export function QueryParameterComponent(props: { entity: EntityDescription, search: Attribute[], params: QueryParameter[], index: number, dispatch: (ce: ChangeEvent) => void }) {
  const param = props.params[props.index];
  const attribute = props.search.find(a => a.uri === param.uri);
  const value = param.value;

  const attributesUsedByGuards = useMemo(() => {
    const guardAttributes: { [uri: string]: boolean } = {};
    for (const attr of props.search) {
      if (attr.kind === 'http://olyro.de/mondiview/category') {
        for (const value of attr.values) {
          for (const guard of value.guards) {
            guardAttributes[guard.attribute] = true;
          }
        }
      }
    }
    return props.search.filter(a => guardAttributes[a.uri]);
  }, [props.search]);

  type GuardValue = {
    uri: string;
    value: string;
  }
  const guardValues: string = useMemo(() => {
    return JSON.stringify(
      attributesUsedByGuards.map(a => {
        const value = props.params.find(p => p.uri === a.uri);
        const gv: GuardValue = {
          uri: a.uri,
          value: value ? value.value : ""
        }
        return gv;
      })
    );
  }, [props.search, props.params, attributesUsedByGuards]);

  const matchGuard = useCallback((guards: CategoryEntryGuard[]): boolean => {
    if (guards.length === 0) return true;
    const guardValuesParsed = JSON.parse(guardValues) as GuardValue[];
    return guards.some(g => {
      const param = guardValuesParsed.find(gv => gv.uri === g.attribute);
      if (param === undefined || param.value === "") {
        return true;
      } else {
        return param.value === g.value;
      }
    });
  }, [guardValues]);

  const categoryValues: CategoryEntry[] = useMemo(() => {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    const categoryValues = !attribute || attribute.kind !== "http://olyro.de/mondiview/category" ? [] : attribute.values.filter(v => matchGuard(v.guards) && v.label.toLocaleLowerCase().indexOf(value.toLowerCase()) !== -1);
    categoryValues.sort((a, b) => collator.compare(a.label, b.label));
    return categoryValues;
  }, [attribute, value, matchGuard]);

  const langContext = useContext(LangContext);
  const translate = (key: string): string => {
    return Translation.getTranslation(key, langContext);
  }

  const shortenRef = (ref: string): string => {
    const parts = ref.split("/");
    return parts[parts.length - 1];
  }

  if (attribute) {
    const input = (() => {
      switch (attribute.kind) {
        case 'http://olyro.de/mondiview/number':
          if (attribute.searchSpan) {
            const [from, to] = param.value.split('-')
            return <span>
              {translate("spanFrom")}
              <input value={from} onChange={e => props.dispatch({ kind: "ChangeParameterValue", index: props.index, newValue: e.target.value + "-" + to })} type="number" />
              {translate("spanTo")}
              <input value={to} onChange={e => props.dispatch({ kind: "ChangeParameterValue", index: props.index, newValue: from + "-" + e.target.value })} type="number" />
            </span>;
          } else {
            return <input value={param.value} onChange={e => props.dispatch({ kind: "ChangeParameterValue", index: props.index, newValue: e.target.value })} type="number" />;
          }
        case 'http://olyro.de/mondiview/string': return props.entity.referenceAttribute === attribute?.uri && attribute?.searchOrder === undefined ?
          <span>{shortenRef(param.value)}</span> :
          <input value={param.value} onChange={e => props.dispatch({ kind: "ChangeParameterValue", index: props.index, newValue: e.target.value })} readOnly={attribute.searchOrder === undefined} />;
        case 'http://olyro.de/mondiview/pdf': return null;
        case 'http://olyro.de/mondiview/category': return <Autosuggest
          shouldRenderSuggestions={() => true}
          suggestions={categoryValues}
          onSuggestionsFetchRequested={() => { }}
          onSuggestionsClearRequested={() => { }}
          getSuggestionValue={id => id.label || id.value}
          renderSuggestion={s => <div>{s.label}</div>}
          inputProps={({
            placeholder: "",
            value: param.value,
            onChange: (_, newValue) => props.dispatch({ kind: "ChangeParameterValue", index: props.index, newValue: newValue.newValue })
          })}
        />
        case 'http://olyro.de/mondiview/imageCollection': return null;
        case 'http://olyro.de/mondiview/htmlContent': return null;
        case 'http://olyro.de/mondiview/htmlImageCollection': return null;
        case 'http://olyro.de/mondiview/substringSearchText': return <input value={param.value} onChange={e => props.dispatch({ kind: "ChangeParameterValue", index: props.index, newValue: e.target.value })} />;
        case 'http://olyro.de/mondiview/entity': return <input value={param.value} onChange={e => props.dispatch({ kind: "ChangeParameterValue", index: props.index, newValue: e.target.value })} readOnly={attribute.searchOrder === undefined} />;
        // handle reference a string for now. This is not perfect, but should be enough for now
        case 'http://olyro.de/mondiview/reference': return <input value={param.value} onChange={e => props.dispatch({ kind: "ChangeParameterValue", index: props.index, newValue: e.target.value })} readOnly={!attribute.searchOrder === undefined} />;
        default: return assertNever(attribute);
      }
    })();

    return input === null ? null : <div className={"searchQueryInput " + uriToClasName(attribute.uri)}>
      <div className="searchLabel">{attribute.label}</div>
      {input}
      <div onClick={() => props.dispatch({ kind: "RemoveParameter", index: props.index })} className="searchClose">x</div>
    </div>
  } else {
    return null;
  }
}

type ChangeEvent = {
  kind: "AddParameter";
  param: QueryParameter;
} | {
  kind: "ChangeParameterValue";
  index: number;
  newValue: string;
} | {
  kind: "RemoveParameter";
  index: number;
} | {
  kind: "Set";
  parameters: QueryParameter[]
}

const reduce = (qs: QueryParameter[], ce: ChangeEvent): QueryParameter[] => {
  switch (ce.kind) {
    case "AddParameter": return qs.concat([ce.param]);
    case "RemoveParameter": return qs.filter((_, i) => i !== ce.index);
    case "ChangeParameterValue": {
      const newArr = qs.concat([]);
      newArr.splice(ce.index, 1, { ...qs[ce.index], value: ce.newValue });
      return newArr;
    }
    case "Set": return ce.parameters;
    default: return assertNever(ce);
  }
}
