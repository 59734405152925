import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useContext } from 'react';
import { useUrlData } from '../../hooks/useUrlData';
import { AttributeWithData } from "../../model/attribute";
import { Entity } from "../../model/entity";
import Translation from '../../translation/Translation';
import LangContext from '../app/App';
import { Params } from './Params';
import './SidePanel.scss';
import { getDownloadUrl, getIDfromParams, getInlineAttribute, getPdfUrlFromAttributes } from './View';

type SidePanelProps = {
  headerAttributes: AttributeWithData[];
  entity: Entity | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDocumentChange: (params: Params) => void;
}
const SidePanel = ({ headerAttributes, entity, open, setOpen, onDocumentChange }: SidePanelProps) => {
  const [doc] = useUrlData<Params>()('document');
  const langContext = useContext(LangContext);
  const translate = (key: string): string => Translation.getTranslation(key, langContext);

  const closeDocumentInfo = () => setOpen(false);
  const openDocumentInfo = () => setOpen(true);

  const getURL = (): string => "" + window.location.origin + "/d/" + getIDfromParams(doc);
  const copyLink = () => navigator.clipboard.writeText(getURL());

  const goToFile = (link: string | undefined) => {
    if (link) window.location.href = link;
  }

  const downloads = entity?.attributes.filter(r => r.attribute.documentPosition.download !== undefined)

  const renderPrintButton = (url: string | undefined, label: string | undefined = undefined, key: string | undefined = undefined) => {
    if (url !== undefined) {
      return (
        <tr className={"show"} key={key || (getIDfromParams(doc) + "_link")}>
          <td className="short-link-item" colSpan={2}>
            <div><span>{label || translate("printView")}:</span></div>
            <input type="button" onClick={() => goToFile(url)} value={translate("printButton")} />
          </td>
        </tr>
      )
    }
  }

  if (headerAttributes.length === 0 || !entity) return <></>;

  const getSidepanelRow = (a: AttributeWithData) => {
    if (!a.data) {
      return <tr className="hide" key={a.attribute.uri}>
        <td className="name">{a.attribute.label}</td>
        <td className="value"></td>
      </tr>
    } else {
      const { value, label, error } = getInlineAttribute(a, (doc) => onDocumentChange({ document: doc, search: entity.description.uri }))
      if (label) {
        return <tr className="show" key={a.attribute.uri}>
          <td className="name">{label}</td>
          <td className="value">{value}</td>
        </tr>
      } else {
        return <tr className="show" key={a.attribute.uri}>
          <td className="value" colSpan={2}>
            {value || error}
          </td>
        </tr>
      }
    }
  }

  if (open) {
    return (
      <div className="documentRight">
        <div
          onClick={() => closeDocumentInfo()}
          className="documentRight-minimize"
          title={translate("foldin")}>
          <FontAwesomeIcon icon={faCaretRight} />
        </div>
        <div className="documentRight-info">
          <table>
            <tbody>
              {
                entity.attributes.filter(r => r.attribute.documentPosition.right !== undefined)
                  .sort((a, b) => a.attribute.documentPosition.right! - b.attribute.documentPosition.right!)
                  .map(getSidepanelRow)
              }
              <tr className={"show"} key={getIDfromParams(doc)}>
                <td className="short-link-item" colSpan={2}>
                  <div><span>{translate("doc_link")}</span></div>
                  <div className="short-link-text">
                    <input id="short-link-input" type={"text"} readOnly={true} value={getURL()}></input>
                    <button className="short-link-copy"
                      onClick={() => copyLink()}
                      title={translate("copy_link")}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
                        <rect x="9" y="3" width="6" height="4" rx="2"></rect>
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
              {
                downloads && downloads.length > 0 &&
                <tr className="show" key="downloadLinks">
                  <td className="short-link-item" colSpan={2}>
                    <div>{translate("downloads")}</div>
                    <ul className="downloadList">
                      {downloads
                        .sort((a, b) => a.attribute.documentPosition.download! - b.attribute.documentPosition.download!)
                        .map(a =>
                          <li key={a.attribute.uri}>
                            <input type="button" onClick={() => goToFile(getDownloadUrl(a))} value={a.attribute.label} />
                            {getDownloadUrl(a)}
                          </li>
                        )
                      }
                    </ul>
                  </td>
                </tr>
              }

              {renderPrintButton(getPdfUrlFromAttributes(entity))}
            </tbody>
          </table>
        </div>
      </div>
    )
  } else {
    return (
      <div className="documentRight">
        <div className="sticky">
          <div onClick={() => openDocumentInfo()} className="documentRight-maximize" title={translate("foldout")}><FontAwesomeIcon icon={faCaretLeft} /> </div>
        </div>
      </div>
    )
  }
}
export default SidePanel;
