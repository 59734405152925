import json from "./translation.json";

interface LanguageItem {
  language: string;
  translation: string;
}

export class Translation {

  public static getTranslation = (key: string, language: string): string => {
    let langItem: LanguageItem | undefined = json.items.find(t => t.key === key)?.langItems.find(k => k.language === language);
    if (langItem) {
      return langItem.translation;
    }
    return "Translation Missing";
  }
}

export default Translation;
